//
// Forms.scss
//


// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;
  ;

  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }

  .form-check-label {
    display: block;
  }
}

.form-check {
  position: relative;
  text-align: left
    /*rtl: right*/
  ;
}


.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

.steps {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  color: #BC263A;
}

.error-message {
  color: #BC263A;
  font-size: 16px;
}

.terms{
  margin-left: 6px;
  margin-top: 15px;
}

.terms-font{
  font-weight: bold;
  cursor:pointer;

}

.modal-iframe{
  width:100%;
  height: 500px;
}

.container {
  position: relative;
}

.watermark-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.watermark-text {
	background: #333;
  opacity: 0.5;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;	
}
    
  // {width: '100%', height: 500 }}

// .otp-input{
//   width: 100%;
//   margin: 5px;
//   height: 55px;
//   border-radius: 10px;
//   left: 520px;
//   padding: 20px;
//   border-top: 0;
//   border-right: 0;
//   border-left: 0;
//   font-size: 18px;
// }

.payment-installment {
  background-color: #BB2639;
}

.payment-number2 {
  align-items: center;
  padding-top: 18px;
  color: #BB2639;
}

.payment-detail {
  align-items: center;
  align-self: center;
  padding-top: 18px;
}

.installment-text {
  background-color: #FFFAFA;
}
.installment-text-bg{
  background-color: black;
}


.payment-color {
  color: #BB2639;
}

.payment-number-style {
  border-color: #BB2639;
  border-width: 2px;
}

.payment-number {
  border-color: #bb2639;
  border-width: 2;
  background-color: white;
}

.payment-modal-width{
  width: 120px;
}

.payment-button-style{
  padding: 8px;
  background-color: #BB2639;
  border-radius: 8px;
  color: white;
  border-color: #bb2639;
}

.payment-modal-popup{
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.link-style {
  padding-left: 60;
  padding-right: 60;
  margin-left: 12
}

.otp-input-field {
  width: "60.97px";
  margin: "5px";
  height: "55px";
  border-radius: "10px";
  left: "520px";
  padding: "20px"

}

.image-heading {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #BC263A;
}

#formFile::before {
  content: "Pick Image";
  position: absolute;
  z-index: 2;
  display: block;
  background-color: #eee;
  width: 80px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.card-scroll {
  width: 100%;
  height: 100%;
  // overflow-y: auto;
}

.phone-input {
  height: 50px;
  border-radius: 10px;
  padding: 18px;
  gap: 20px;
  background-color: #FFFAFA;
}

.cursor-style {
  cursor: pointer
}

// .otpInput {
//     padding-left: 15px;
//     letter-spacing: 42px;
//     border: 0;
//     background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
//     background-position: bottom;
//     background-size: 50px 1px;
//     background-repeat: repeat-x;
//     background-position-x: 35px;
//     width: 220px;
//     outline : none;

// }
// .divInner{
//   left: 0;
//   position: sticky;
// }

// .divOuter{
//   width: 190px; 
//   overflow: hidden;
// }

.step-title1 {
  position: absolute;
  width: 64px;
  height: 13px;
  left: 0px;
  top: 0px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;

  /* third color */

  color: #41445E;

}

.font-style {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  color: #9C9C9C;
}

.enter-number {
  width: 400px;
  height: 20.75px;
  text-align: left;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #9C9C9C;

}

.main-logo {
  position: absolute;
  width: 160px;
  height: 117.33px;

  top: 57px;

}